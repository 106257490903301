const lengthRequirement = /.{8,}/
const lowerCaseRequirement = /[a-z]/
const upperCaseRequirement = /[A-Z]/
const numberRequirement = /[0-9]/

export const validatePasswordStrength = (password: string): void => {
  if (!lengthRequirement.test(password)) {
    throw new Error('Password must be at least 8 characters long')
  }
  if (!lowerCaseRequirement.test(password)) {
    throw new Error('Password must contain at least one lowercase letter')
  }
  if (!upperCaseRequirement.test(password)) {
    throw new Error('Password must contain at least one uppercase letter')
  }
  if (!numberRequirement.test(password)) {
    throw new Error('Password must contain at least one number')
  }
}

export const PasswordCriteriaLabelDescription = `
    <strong>Password must contain:</strong>
    <br>
    At least 8 characters
    <br>
    Lower case letters (a-z)
    <br>
    Upper case letters (A-Z)
    <br>
    Numbers (0-9)
  `
